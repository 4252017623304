let version = "24.08.0-0.4.0";

let domain = window.location.origin + "/api";
let eventBranchKey = "key_live_hvaUEImtcYqk5BhqGOPKmcdhqvayjU1z"
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    domain = "https://catmo-sandbox.statussquad.dev/api";
}

if (window.location.hostname === "catmo-sandbox.statussquad.dev" || window.location.hostname === "localhost") {
    eventBranchKey = "key_test_aCnSwSiCj3yd4uhtHVSRydnoBqeudSZI"
}

const appConfig =  {
    domain: domain,
    version: version,
    key : eventBranchKey
};

export default appConfig;
